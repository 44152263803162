import logo from "./logo.svg";
import styles from "./styles.css";
import "./App.css"; // Import your styles
import { Children, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import Postlll from "./posts/chronot.js";
import axios from "axios";

// import use media

import {
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// importt firebase storeage

import { getBlob, getDownloadURL, getStorage, ref } from "firebase/storage";

import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import Markdown from "react-markdown";

const components = {
  em(properties) {
    return <i {...properties} />;
  },
};

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #767676;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: ${spin} 0.6s linear infinite;
`;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKIaU8XN11pkFrbjjG8NfHRNcKO4GMyFE",
  authDomain: "girgin-co.firebaseapp.com",
  projectId: "girgin-co",
  storageBucket: "girgin-co.appspot.com",
  messagingSenderId: "459696271212",
  appId: "1:459696271212:web:a72f17787986ca4e068d49",
  measurementId: "G-0VJ7FZ8CF6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

const getLinks = async () => {
  isFetchingSomething = true;
  const links = await collection(db, "links");
  const linksSnapshot = await getDocs(links);
  const linksList = linksSnapshot.docs.map((doc) => doc.data());
  isFetchingSomething = false;

  return linksList;
};

const getBooks = async () => {
  isFetchingSomething = true;
  const books = await collection(db, "books");
  const booksSnapshot = await getDocs(books);
  const booksList = booksSnapshot.docs.map((doc) => doc.data());

  // Sort the books by the dateRead property
  booksList.sort((a, b) => {
    const [dayA, monthA, yearA] = a.dateRead.split(".").map(Number);
    const [dayB, monthB, yearB] = b.dateRead.split(".").map(Number);
    const dateA = new Date(yearA, monthA - 1, dayA); // month is zero-based in JavaScript Date
    const dateB = new Date(yearB, monthB - 1, dayB); // month is zero-based in JavaScript Date
    return dateB - dateA;
  });

  isFetchingSomething = false;

  return booksList;
};

let isFetchingSomething = false;

let books = [];

let links = [];

let posts = [];

const getPosts = async () => {
  isFetchingSomething = true;
  const postss = await collection(db, "posts");
  const postsSnapshot = await getDocs(postss);
  const postsList = postsSnapshot.docs.map((doc) => doc.data());
  isFetchingSomething = false;
  posts = postsList;

  return postsList;
};

function ReadBi() {
  return (
    <div className="flex flex-col gap-0 ">
      <div className="flex flex-row gap-4 pt-20">
        <img
          className="rounded-3xl w-2/5"
          src="/images/readbi.png"
          alt="Readbi"
        />
        <div>
          <h1 className="text-white text-3xl font-bold"> ReadBi</h1>{" "}
          <h1 className="text-gray-400 text-xl ">
            Read docs bilingually without reaching dictionary
          </h1>
          <a href="https://apps.apple.com/mk/app/readbi/id6468990192">
            {" "}
            Click to see on appstore.{" "}
          </a>
        </div>
      </div>

      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Motivation </h1>
        <p className="text-gray-400 text-lg">
          {" "}
          I was learning German and I was reading a lot of articles. I was
          translating every word that I didn't know. It was really annoying to
          reach the dictionary every time.
        </p>
      </div>
      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Solution </h1>
        <p className="text-gray-400 text-lg">
          I got inspired from Amazon's one-click-buy feature. I made an app that
          purposefully designed to be simple and easy to use. You can read the
          article and when you click on a word, it will be translated to the
          language that you want.
        </p>
      </div>
    </div>
  );
}

function Track() {
  return (
    <div className="flex flex-col gap-0 ">
      <div className="flex flex-row gap-4 pt-20">
        <img
          className="rounded-3xl w-2/5"
          src="/images/track.png"
          alt="Track"
        />
        <div>
          <h1 className="text-white text-3xl font-bold">Track!</h1>{" "}
          <h1 className="text-gray-400 text-xl ">Track what you care most</h1>
          <a href="https://apps.apple.com/mk/app/track/id6471897749">
            {" "}
            Click to see on appstore.{" "}
          </a>
        </div>
      </div>

      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Motivation </h1>
        <p className="text-gray-400 text-lg">
          {" "}
          I was eager to learn SwiftUI and SwiftUI frameworks. I also have lots
          of things in my life that I want to see how I do on them over time.
        </p>
      </div>
      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Solution </h1>
        <p className="text-gray-400 text-lg">
          I decided to scratch that itch so I built Track! It's a simple app
          that you can track your habits, your mood, your weight, or anything
          that you care most.
        </p>
      </div>
    </div>
  );
}

function WorkWhere() {
  return (
    <div className="flex flex-col gap-0 ">
      <div className="flex flex-row gap-4 pt-20">
        <img className="rounded-3xl w-2/5" src="/images/ww.png" alt="Track" />
        <div>
          <h1 className="text-white text-3xl font-bold">WorkWhere</h1>{" "}
          <h1 className="text-gray-400 text-xl ">
            Find workplaces that best for study and remote work
          </h1>
          <a href="https://apps.apple.com/mk/app/workwhere/id6478559851">
            {" "}
            Click to see on appstore.{" "}
          </a>
        </div>
      </div>

      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Motivation </h1>
        <p className="text-gray-400 text-lg">
          I love working at coffee shops. But I always have a problem with
          finding a good place to work. I was using Google Maps to find a place
          to work but it wasn't spesific for my purpose. I am aware of the beaty
          of simplicity and reduction of the complexity. I decided to make a
          simple app that only shows the places that are suitable for working.
        </p>
      </div>
      <div className="">
        <h1 className="text-white text-2xl font-bold pt-20"> Solution </h1>
        <p className="text-gray-400 text-lg">
          We built WorkWhere. It is just for people who want to work at coffee
          shops. It shows the places that are suitable for working. It's simple
          and easy to use. You can see the places on the map and you can see the
          details of the places. You can also see the reviews of the places. It
          is also possible to add a new place to the app. I hope it helps you to
          find a good place to work.
        </p>
      </div>
    </div>
  );
}

function Home() {
  return (
    <div className=" pb-96  flex flex-col h-full  ">
      <div
        className={`text-white text-left ${
          "" // width > 600 ? "w-4/12" : "w-full"
        } mt-20 `}>
        <div className="text-xl">
          <div className="font-thin">
            Biraz kod yazıyorum,
            <br />
            Biraz fotoğraf çekiyorum,
            <br />
            Genelde o dönem ne hoşuma giderse bi süre onunla ilgileniyorum.
            <br />2 sene psikoloji ve bilim okudum ama son zamanlarda çoğunlukla
            ikna ve iletişim kitapları okumayı seviyorum.
          </div>

          <div className="pt-8">
            <a href="/links">
              İnternette gezinirken hoşuma giden linkleri burada topluyorum.
            </a>
          </div>
          <div className="pt-8">
            <a href="/books">
              Son zamanlarda okuduğum kitapları burada topluyorum.
            </a>
          </div>

          <div className="pt-8">
            <a href="/posts">Yazdığım kısa yazıları da burada topluyorum.</a>
          </div>
        </div>
      </div>
      <div className="">
        <div className="h-4/6 pt-8 pb-8 aspect-auto">
          <img src="./images/m.jpg" alt="Mustafa Girgin" />{" "}
        </div>
      </div>
      <div className="">
        <h1 className="text-2xl block text-gray-300">
          Aşina olduğum teknolojiler
        </h1>
        <h3 className="text-sm text-gray-600">*hiçbirinde uzman değilim</h3>
        <hr />{" "}
      </div>
      <div className={`  text-gray-400`}>
        <div className="   ">
          <FamiliarTech
            name="Swift - SwiftUI"
            description=<div>
              <a href="https://apps.apple.com/mk/developer/mustafa-girgin/id1711182497">
                App store'da
              </a>{" "}
              3 tane uygulamam var
              <div className="flex-row  pt-10 flex  w-full gap-3 ">
                <div className=" w-[400px]  block">
                  <img
                    className="rounded-3xl"
                    src="./images/readbi.png"
                    alt="ios"
                  />

                  <a
                    href="/apps/readbi"
                    className=" flex  justify-center w-full">
                    {" "}
                    Readbi
                  </a>
                </div>
                <div className="  w-[400px]">
                  <img
                    className="rounded-3xl"
                    src="./images/ww.png"
                    alt="ios"
                  />
                  <a
                    href="/apps/workwhere"
                    className=" flex  justify-center w-full">
                    {" "}
                    WorkWhere
                  </a>
                </div>
                <div className=" w-[400px]  block">
                  <img
                    className="rounded-3xl"
                    src="./images/track.png"
                    alt="ios"
                  />
                  <a
                    href="/apps/track"
                    className=" flex  justify-center w-full">
                    {" "}
                    Track!
                  </a>
                </div>
              </div>
            </div>
          />
          <FamiliarTech
            name="Flutter"
            description={`Şu an duolingo benzeri bir uygulama geliştiriyorum. Henüz hazır değil. Benim projem değil.`}
          />

          <FamiliarTech
            name="React - Javascript - Tailwind"
            description={`Bu siteyi bunları kullanarak yaptım.`}
          />

          <FamiliarTech
            name="Firebase"
            description={`Bu siteyi ve diğer uygulamarımın backend'ini Firebase kullanarak yapıyorum.`}
          />

          <FamiliarTech
            name="Fl Studio"
            description=<div>
              Şu an fl studio öğrenmeye çalışıyorum. GarageBand ile yaptığım
              müzikler{" "}
              <a href="https://soundcloud.com/mustafa-dalkesen">burada.</a>
            </div>
          />
        </div>
      </div>

      <div className="text-left w-full ">
        <h1 className="text-2xl text-gray-300">İletişim ve hesaplar</h1>
        <hr />{" "}
      </div>

      <div className=" space-x-4  w-full justify-start justify-left pt-5">
        <OutlineLinkButton
          link="mailto:mustafadalkesen11@gmail.com"
          linkText="Email"></OutlineLinkButton>
        <OutlineLinkButton
          link="https://github.com/antelcha"
          linkText="Github"></OutlineLinkButton>
      </div>
    </div>
  );
}
function OutlineLinkButton({ link, linkText }) {
  return (
    <button
      onClick={() => {
        // mail to mustafadalkesen11@gmail.com on click
        window.location.href = link;
      }}
      className="border border-gray-500 hover:border-white text-white font-bold py-2 px-4 rounded-3xl">
      {linkText}
    </button>
  );
}

function FamiliarTech({ name, description }) {
  return (
    <div className="py-10">
      <h1 className="text-[24px] text-gray-300 ">{name}</h1>
      <div className="text-gray-400">{description}</div>
    </div>
  );
}

function PostView({ post }) {
  return (
    <div className="flex flex-col  ml-auto mr-auto justify-left  text-white  ">
      <a href={"/posts/" + post.id} className="text-2xl">
        {post.title}
      </a>
      <p className="text-gray-400">{post.littleDescription}</p>
      <div className="pt-4 pb-4 text-gray-500">
        {/* <ReactTimeAgo date={date} locale="en-US" /> */}
        <h1>{post.date}</h1>
      </div>
      <hr className="bg-gray-200 " />
    </div>
  );
}

function PostDetailView() {
  const params = useParams();

  const [post, setPost] = useState({});
  useEffect(() => {
    getPosts().then((postsF) => {
      postsF.map((post) => {
        if (post.id === params.postId) {
          setPost(post);
        }
      });
    });
  }, []);

  const [postContent, setPostContent] = useState(null);

  useEffect(() => {
    console.log(post.id);
    // Dynamically import the post content based on post id

    import(`./posts/${params.postId}`)
      .then((module) => {
        setPostContent(module.default);
      })
      .catch((error) => {
        console.error("Error loading post content:", error);
      });
  }, []);
  console.log(postContent);

  return (
    <div className="flex flex-col pt-20 ml-auto mr-auto justify-left text-white">
      <h1 className="text-2xl">{post.title}</h1>
      {postContent && <div className="text-gray-400">{postContent}</div>}
      <div className="pt-4 pb-4 text-gray-500">
        <h1>{post.date}</h1>
      </div>
      <hr className="bg-gray-200" />
    </div>
  );
}

function Posts() {
  const [postsF, setPosts] = useState([]);
  useEffect(() => {
    getPosts().then((postsF) => {
      setPosts(postsF);
    });
  }, []);

  // convert the links to a list of LinkView components:
  posts = postsF;

  return (
    <div className="flex flex-col text   text-white">
      <p className="font-bold  pt-20  text-3xl ">Kısa yazılar ve notlar</p>
      <h1 className="pt-4 pb-20 text-2xl text-gray-400"> Son Eklenenler </h1>
      {isFetchingSomething === true && <Loader></Loader>}
      <ol>
        {posts.map((post) => (
          <div key={post.id} className="py-10">
            {" "}
            <PostView post={post} />{" "}
          </div>
        ))}
      </ol>
    </div>
  );
}

function LinkView({ link, date }) {
  return (
    <div className="pt-4  flex flex-col  ml-auto mr-auto justify-left  text-white ">
      <a href={link.link}>{link.linkText}</a>
      {link.description && (
        <p className="text-gray-400 pt-4">{link.description}</p>
      )}
      <div className="pt-4 pb-4">
        {/* <ReactTimeAgo date={date} locale="en-US" /> */}
      </div>
      <hr className="bg-gray-200 " />
    </div>
  );
}

function Links() {
  // get all the links from the database
  const [linksF, setLinks] = useState([]);
  useEffect(() => {
    getLinks().then((linksF) => {
      setLinks(linksF);
    });
  }, []);

  // convert the links to a list of LinkView components:
  links = linksF;

  return (
    <div className="flex flex-col  ml-auto mr-auto justify-left  text-white">
      <p className="font-bold  pt-20  font-light text-3xl ">
        İnternette gezinirken beğendiğim ve beni sevenlerin de beğeneceğini
        düşündüğüm, belli bir kategorisi olmayan karışık şeyler.
      </p>

      <h1 className="pt-4 pb-20 text-2xl text-gray-400"> Son Eklenenler </h1>
      {isFetchingSomething === true && <Loader></Loader>}
      <ol>
        {links.map((link) => (
          <LinkView link={link} />
        ))}
      </ol>
    </div>
  );
}

function BookView({ book }) {
  return (
    <div className="flex flex-row py-10 ">
      <div className="w-1/4">
        <img className="aspect-auto " src={book.imgLink}></img>
      </div>

      <div className="flex flex-col pl-10 w-3/4">
        <div className="text-3xl font-bold">
          <a href={book.link}>{book.title}</a>
        </div>
        <div className="text-xl font-thin">
          <h1>{`Okuduğum tarih: ${book.dateRead}`}</h1>
        </div>

        <div className="text-xl font-thin pt-[20px]">
          {book.description && <h1>{` ${book.description}`}</h1>}
        </div>
      </div>
    </div>
  );
}

function Books() {
  // get all the links from the database
  const [booksF, setBooks] = useState([]);
  useEffect(() => {
    getBooks().then((linksF) => {
      setBooks(linksF);
    });
  }, []);

  // convert the links to a list of LinkView components:
  books = booksF;

  return (
    <div className="flex flex-col  ml-auto mr-auto justify-left  text-white">
      <p className="font-bold  pt-20  font-light text-3xl ">
        Son zamanlarda okuduğum kitaplardan bazılarını buraya koyuyorum.
      </p>

      <h1 className="pt-4 pb-20 text-2xl text-gray-400">
        {" "}
        2024'ten önceki kitaplar yok{" "}
      </h1>
      {isFetchingSomething === true && <Loader></Loader>}
      <ol>
        {books.map((book) => (
          <BookView book={book} />
        ))}
      </ol>
    </div>
  );
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "/apps/readbi",
      element: <ReadBi />,
    },
    {
      path: "/apps/track",
      element: <Track />,
    },
    {
      path: "/apps/workwhere",
      element: <WorkWhere />,
    },

    {
      path: "/posts",
      element: <Posts />,
    },
    {
      path: "/links",
      element: <Links />,
    },

    {
      path: "/books",
      element: <Books />,
    },
    {
      path: "/posts/:postId",
      element: <PostDetailView />,
    },
  ]);

  const [index, setIndex] = useState(0);

  // with using useEffect, monitor the screen width and set the width state
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    switch (router.state.location.pathname) {
      case "/":
        setIndex(0);
        break;
      case "/posts":
        setIndex(1);
        break;
      case "/links":
        setIndex(2);
        break;
      case "/books":
        setIndex(3);
        break;
      default:
        setIndex(0);
    }
  }, [router.state.location.pathname]);

  const colorVariants = {
    blue: "bg-blue-600 hover:bg-blue-500",
    red: "bg-red-600 hover:bg-red-500",
  };

  // get the current route name

  return (
    <div
      className={`

      
        ${width > 700 ? "w-[700px]" : "w-screen"}
      justify-center p-4
         block  min-h-full flex-col`}>
      <div className="flex flex-col  h-full">
        <div className="">
          <a href="/" className="font-bold text-3xl">
            Mustafa Girgin
          </a>
        </div>
      </div>

      <RouterProvider router={router} />
    </div>
  );
}

export default App;
