// write a div and then export it
import React from "react";

export default function Post() {
  return (
    <div>
      <p>
        Chronotherapy, bozulan sirkadiyen ritmi düzeltmek için kullanılan bir
        yöntemmiş. Ama sanırım genel bi tedavi olarak görülmüyor. Çünkü
        insanların “chronotype”ları farklı olabiliyormuş ve bu yöntem onlarda
        işe yaramıyormuş. Chronotype basitçe gece mi ayakta kalmayı daha çok
        tercih ediyoruz yoksa sabah mı, bunu söylüyor ve genetik olduğu
        söyleniyor. Ama ben bu kısmı çok kurcalamadım.
      </p>
      <p>
        Şimdi ben uzun bir süre 3-10 arası uyuyan biriydim. Sabah okula gittiğim
        dönemler veya erkenden bir yere gitmek gerektiği zamanlar çoğalınca uyku
        düzenim bu şekildeydi. Ama son bir yıldır sabah erken kaldıracak tüm
        sorumluluklardan kaçıyorum çok istisna olmadıkça, o yüzden olmayan uyku
        düzenim daha da bozuldu. Son zamanlarda artık 7-14 arası uyuyodum.
        Uykuya dalmakta vs problem yaşamıyorum ama evde kalmayı çok sevmiyorum.
        O yüzden bu düzen de beni bunaltmıştı.
      </p>
      <p>
        Bu sefer bambaşka bir şey denedim, tüm gece ayakta kaldım yine, ama evde
        değildim. Eve 9 gibi geliyodum. Gelince direkt bayılıyordum zaten. Bu
        sefer 9-16 gibi bi düzene geçtim, bu düzen baya hoşuma gitti. Tek
        sıkıntı sabah güneşini ve akşamüzerine kadarki zamanları inanılmaz
        özledim.
      </p>
      <p>
        Sonra hackernews’te chronotherapy’den bahsedildiğini gördüm. Daha önce
        uykuyla ilgili biraz araştırma yapmıştım ama o zamana kadar duymadım
        ismini. Tavsiye ettiği şey şu eğer sabah 9’da uyuyorsak bir hafta
        boyunca istenilen uyku saati gelinceye kadar, her gün uyku saatini 3
        saat ileri ötelemek. Yani, ilk gün 9 ikinci gün 12 üçüncü gün 15
        dördüncü gün 18 beşinci gün 21 altıncı gün 23 (ben böyle yaptım)
      </p>
      <p>
        Şimdi bu kenarda dursun, biraz jet-lag’ten bahsedicem. Bizim saatleri
        bilen bi iç ritmimiz var. Kendisini göze giren ışığa göre ayarlıyor.
        Güneş doğunca sabah olduğunu, battığında ve artık etrafta ışık
        olmadığında gece olduğunu anlıyormuş. Işık olmadığında vücudun ritmini
        24 saat 5 dakika olarak ölçmüşler. Yani bu şu demek bizim zaten
        biyolojik bir günümüz, kabul ettiğimiz bir günden biraz uzun. O yüzden
        deseler ki artık günler 24 saat değil 24 saat + 5 dakika, vücut için
        problem yok zaten normali bu. Vücut için günü uzatmak, kısaltmaktan daha
        kolay.
      </p>
      <p>
        Normalde 23’de uyuyorsunuz diyelim. Eğer alıştığınız yerden 5 saat
        ileride bir yere seyehat ederseniz, normalde saat 23 iken vücut 18
        olduğunu düşünecek, hormonlar ona göre salınacak. Eskiden saat 18de
        aktifken artık uykuya dalma zamanı geldiğini vücut hemen öyle
        anlamıyormuş. Bu işlerden sorumlu bölge gözlerin hemen arkasındaki
        suprachiasmatic çekirdek, kendisini anca birer saat ileri olacak şekilde
        adapte edebiliyormuş. Yani normal şartlarda beynin yeni seyehat edilen
        yere alışması 5 gün sürecek. Ama diyelim ki 5 saat geri olan bir yere
        seyehat ettiniz, bu sefer fark +5 değil +19, yani tahmin ediyorum ki
        vücut için bu daha çok vakit alacak. Uyku düzeninde de bence yaptığımız
        şeylerin çoğunun işe yaramamasının sebebi bu. Normalde 3’de uyurken
        birden uyku saatini 23’e 22’ye çekmeye çalışıyoruz. Ritmi, o çekirdek
        sadece ileri doğru kaydırabildiği için bu epey bi zaman alıcak ve
        muhtemelen hiçbirimiz o kadar süre boyunca aynı saatte uyumayı
        beceremeyip pes ediyoruz.
      </p>
      <p>
        Bende chronotherapy işe yaradı gibi duruyor. En son gün 22.30’da uyuyup
        5.30’da kalktım, sıfır alarm ile. Arkasındaki sebebin üstteki mekanizma
        olduğunu düşünüyorum. Biraz üzerinden zaman geçsin hala döngüye sahip
        çıkabiliyor muyum bakacağım. Bir problem olursa tekrar düzenlerim bu
        yazıyı.
      </p>
    </div>
  );
}
